import { CSSProperties } from "react";
import HashLoader from "react-spinners/HashLoader";
import { Typography } from "@mui/material";

// const loaderCss = { display: "block", margin: "0 auto", border-color: "red"}

const override: CSSProperties = {
  display: "block",
  margin: "100px auto",
  borderColor: "red",
};

const divStyle: CSSProperties = {
  width: "100vw",
  height: "100vh",
  color: "#262626",
  backgroundColor: "#A78BFA",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

interface Props {
  message?: string;
  loading?: boolean;
}

const SplashScreen = ({ message, loading }: Props) => {
  return (
    <div style={divStyle}>
      {message ? (
        <Typography variant={"h3"} align="center">
          {message}
        </Typography>
      ) : (
        <Typography variant={"h1"} align="center">
          knockify{" "}
        </Typography>
      )}
      {/* <h1>Knockify</h1> */}
      <HashLoader cssOverride={override} size={100} loading={loading ?? true} />
    </div>
  );
};

export default SplashScreen;
