import api from "./base";

export const getAddressesByMapId = async (
  id: number,
  campaignId: number | undefined = -1,
  testMode: boolean = false,
) =>
  await api.getData(
    `/${testMode ? "test_addresses" : "addresses"}/${id}/${campaignId}`,
  );
