import { StrictMode, useEffect, useState } from "react";
import "./App.css";
import MainLayout from "./components/layout/MainLayout";
import { SnackbarProvider } from "notistack";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Auth from "./screens/Auth";
import Login from "./screens/Login";
import Root from "./components/Root";
import MapScreen from "./screens/MapSelection";
import Stats from "./screens/Stats";
import Settings from "./screens/Settings";
import Organising from "./screens/Organising";
import useStore from "./utils/store";
import styled from "@emotion/styled";
import { MaterialDesignContent } from "notistack";

function App() {
  // const [isLoading, setIsLoading] = useState(true);
  const theme = useStore((state) => state.theme);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);
  // }, []);

  const router = createBrowserRouter([
    {
      element: <Root />,
      children: [
        {
          element: <MainLayout />,
          children: [
            {
              path: "/",
              element: <MapScreen />,
            },
            {
              path: "/stats",
              element: <Stats />,
            },
            {
              path: "/settings",
              element: <Settings />,
            },
            {
              path: "/organising",
              element: <Organising />,
            },
          ],
        },
        {
          path: "/auth",
          element: <Auth />,
        },
        {
          path: "/login",
          element: <Login />,
        },
      ],
    },
  ]);

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: "hsl(var(--su))",
      color: "hsl(var(--suc))",
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: "hsl(var(--er))",
      color: "hsl(var(--erc))",
    },
  }));

  return (
    <StrictMode>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
      >
        <RouterProvider router={router} />
      </SnackbarProvider>
    </StrictMode>
  );
}

export default App;
