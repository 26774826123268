import {
  ChatBubbleLeftRightIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import ModalDialog from "../../components/layout/ModalDialog";

const AddressPopupHelpModal = () => (
  <ModalDialog id="address_popup_help_modal">
    <div className="text-base-content">
      <h6 className="font-bold text-lg text-center mb-2 text-base-content">
        How to record responses
      </h6>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4 items-center">
          <button className="btn btn-error">
            <XCircleIcon className="h-6 w-6" />
          </button>
          <div>Hit this button if nobody answered the door.</div>
        </div>

        <div className="flex gap-4 items-center">
          <button className="btn btn-neutral">
            <PsychologyAltIcon />
          </button>
          <div>
            Hit this button if you had a brief and noncommittal conversation
            (eg. the person didn't have time to talk or was not interested)
          </div>
        </div>

        <div className="flex gap-4 items-center">
          <button className="btn btn-success">
            <ChatBubbleLeftRightIcon className="h-6 w-6" />
          </button>
          <div>Hit this button if you had a proper conversation.</div>
        </div>
      </div>
    </div>
  </ModalDialog>
);

export default AddressPopupHelpModal;
