import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import useStore from "../utils/store";
import { login } from "../utils/api/login";
import TextInput from "../components/form/TextInput";
import Label from "../components/form/Label";

const Login = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const isAuthed = useStore((state) => state.isAuthed());
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    // console.log("email", email);
    try {
      await login(email);
      setSubmitted(true);
    } catch (err) {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    isAuthed && navigate("/");
  }, [isAuthed]);

  return (
    <div className="bg-primary min-h-screen flex flex-col justify-center items-center ">
      <div className="flex flex-col gap-12 justify-center p-8 max-w-md">
        {submitted ? (
          <>
            <h1 className="text-3xl text-center">Submitted!</h1>
            <h2 className="text-xl text-center">
              Check your email for a link to sign in
            </h2>
          </>
        ) : (
          <>
            <h1 className="text-3xl text-center">Sign in to Knockify</h1>
            <div className="form-control flex flex-col gap-12">
              <div>
                <TextInput
                  className="w-full"
                  id="email"
                  placeholder="Email address"
                  value={email}
                  onChange={setEmail}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") handleSubmit();
                  }}
                />
                <div className="px-4">
                  <Label alt>
                    Enter the email that you used to sign up to Victorian
                    Socialists
                  </Label>
                </div>
              </div>
              <button
                className="btn btn-accent"
                disabled={!email}
                onClick={handleSubmit}
              >
                Submit{" "}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
