import { FunnelIcon } from "@heroicons/react/24/solid";
import { openFilterModal } from "../utils";
import useStore from "../utils/store";

interface Props {
  closePopup: () => void;
}

const FilterButton = ({ closePopup }: Props) => {
  const unansweredThreshold = useStore((state) => state.unansweredThreshold);

  return (
    <button
      className="absolute left-14 bottom-14 z-40 drop-shadow-md"
      onClick={() => {
        closePopup();
        openFilterModal();
      }}
    >
      <FunnelIcon className="h-12 w-12 text-accent" />
      {unansweredThreshold > 1 ? (
        <div className="badge badge-primary badge-sm py-2 font-bold absolute top-0 right-0">
          {unansweredThreshold}
        </div>
      ) : null}
    </button>
  );
};

export default FilterButton;
