import api from "./base";

export const submitResponse = async (
  response: any,
  mapId: number,
  testMode: boolean = false
) => await api.postData("/responses", { ...response, mapId, test: testMode });

export const submitNonAnswerResponse = async (
  addressId: number,
  campaignId: number,
  mapId: number
) =>
  await api.postData("/responses/noanswer", { addressId, campaignId, mapId });

export const submitNonCommittalResponse = async (
  addressId: number,
  campaignId: number,
  mapId: number
) =>
  await api.postData("/responses/noncommittal", {
    addressId,
    campaignId,
    mapId,
  });
