interface IRadioOption {
  label: string;
  value: number;
}

interface Props {
  id: string;
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: IRadioOption[];
}

// Only supports option values that are consecutive integers
const Slider = ({ id, value, onChange, options }: Props) => {
  return (
    <>
      <input
        id={id}
        type="range"
        min={options[0].value}
        max={options[options.length - 1].value}
        value={value}
        onChange={onChange}
        className="range range-primary"
        step="1"
      />
      <div className="w-full flex justify-center text-sm mt-1 px-2">
        <span className="text-center">
          {options.find((option) => option.value === value)?.label}
        </span>
      </div>
    </>
  );
};

export default Slider;
