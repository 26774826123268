import clsx from "clsx";

interface Props {
  id: string;
  label?: string;
  helpText?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  labelBefore?: boolean;
}

const Toggle = ({
  id,
  label,
  helpText,
  value,
  onChange,
  labelBefore = false,
}: Props) => {
  return (
    <>
      <div
        className={clsx("flex items-center gap-2", {
          "flex-row-reverse justify-end": labelBefore,
        })}
      >
        <input
          id={id}
          type="checkbox"
          className="toggle toggle-primary"
          checked={value}
          onChange={() => onChange(!value)}
        />
        {label && (
          <label className="label" htmlFor={id}>
            <span className="label-text">{label}</span>
          </label>
        )}
      </div>
      {helpText && (
        <label className="label">
          <span className="label-text-alt">{helpText}</span>
        </label>
      )}
    </>
  );
};

export default Toggle;
