import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";
import useStore from "../../utils/store";

const MainLayout = () => {
  const user = useStore((state) => state.user);
  const practiceMode = useStore((state) => state.practiceMode);

  if (!user) return null;

  return (
    <>
      <TopBar />
      <div className="px-3">
        <Outlet />
      </div>
      <BottomBar />
      {practiceMode && (
        <div className="fixed top-0 h-full w-full pointer-events-none text-red-500 text-5xl font-bold text-center uppercase flex justify-center items-center opacity-50 z-50 rotate-45">
          Practice Mode
        </div>
      )}
    </>
  );
};

export default MainLayout;
