import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyToken } from "./utils/store";

const Init = () => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("token");
      verifyToken(
        token,
        () => {},
        () => {
          navigate("/login");
        },
      );
    })();
  }, []);

  return null;
};

export default Init;
