import clsx from "clsx";
import { ICampaign, IMap } from "../../types";
import { updateCompletionStatus } from "../../utils/api/maps";
import { useSnackbar } from "notistack";
import { useState } from "react";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

interface Props {
  completionPercentage: number;
  map: IMap;
  campaign: ICampaign;
  reloadMap: () => Promise<void>;
  className: string;
}

const MapProgress = ({
  completionPercentage,
  map,
  campaign,
  reloadMap,
  className,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [runConfetti, setRunConfetti] = useState(false);
  const [recycleConfetti, setRecycleConfetti] = useState(false);
  const { width, height } = useWindowSize();

  const updateMap = async () => {
    try {
      const newStatus = !map.doorknockingCompleted;
      await updateCompletionStatus(
        map.id,
        campaign.id,
        campaign.type,
        newStatus
      );
      reloadMap();

      if (newStatus) {
        setRunConfetti(true);
        setRecycleConfetti(true);
        setTimeout(() => {
          setRecycleConfetti(false);
        }, 3000);
      }

      enqueueSnackbar(
        `Map marked as ${
          map.doorknockingCompleted ? "incomplete" : "complete"
        }`,
        { variant: "success" }
      );
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <div className={clsx("dropdown dropdown-top", className)}>
      {runConfetti && (
        <div className="fixed z-50 top-0 left-0">
          <Confetti
            width={width}
            height={height}
            recycle={recycleConfetti}
            numberOfPieces={500}
            run={runConfetti}
          />
        </div>
      )}

      <label tabIndex={0}>
        <progress
          className={clsx("progress w-32 h-6 drop-shadow-md", {
            "progress-success": map.doorknockingCompleted,
            "progress-accent": !map.doorknockingCompleted,
          })}
          value={map.doorknockingCompleted ? 100 : completionPercentage}
          max="100"
        />
      </label>
      <div
        tabIndex={0}
        className="dropdown-content right-2 mb-4 z-[1] card card-compact w-64 p-2 shadow bg-primary text-primary-content text-right"
      >
        <div className="card-body">
          <h3 className="card-title justify-end">
            {completionPercentage}% of doors knocked
          </h3>
          <button
            className={clsx("btn btn-primary", {
              "btn-success": !map.doorknockingCompleted,
              "btn-error": map.doorknockingCompleted,
            })}
            onClick={updateMap}
          >
            Mark as {map.doorknockingCompleted ? "incomplete" : "complete"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MapProgress;
