import { Box, Paper } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup, Polygon } from "react-leaflet";
import { Map as LeafletMap, Icon, LatLngTuple, DivIcon } from "leaflet";
import { useEffect, useState } from "react";
import { getAllMapPolygons } from "../utils/api/maps";
import MapAssigner from "./MapAssigner";
import { IMap } from "../types";
import useStore from "../utils/store";

const MapBrowser = ({
  municipalityId,
  campaignId,
  letterboxing,
}: {
  municipalityId: number;
  campaignId: number;
  letterboxing?: boolean;
}) => {
  const [centre, _setCentre] = useState<LatLngTuple>([-37.8316, 144.9631]);
  const [maps, setMaps] = useState<IMap[] | undefined>();
  const selectedCampaign = useStore((state) => state.selectedCampaign);

  useEffect(() => {
    const fetchAllMaps = async () => {
      const result = await getAllMapPolygons(municipalityId, campaignId);
      setMaps(result);
    };
    fetchAllMaps();
  }, [selectedCampaign]);

  const mapIsCompleted = (map: IMap) => {
    if (selectedCampaign?.type === "Letterboxing") {
      return !!map.letterboxedTimestamp;
    } else if (selectedCampaign?.type === "Doorknocking") {
      return map.doorknockingCompleted;
    } else {
      return false;
    }
  };

  return (
    <Paper
      sx={{
        top: 8,
        zIndex: 1500,
        width: "100%",
      }}
    >
      <Box
        id="knockify-knocking-screen"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {maps && maps.length > 0 && (
          <MapContainer
            center={centre}
            zoom={16}
            scrollWheelZoom={true}
            zoomControl={false}
          >
            <>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {maps.map((mapItem) => {
                // polygon.bindTooltip("My polygon", {permanent: true, direction:"center"})
                // if (mapItem.name === "NEWAR173") console.log(mapItem);
                let fillColor = "blue";
                if (true || letterboxing) {
                  if (mapIsCompleted(mapItem)) {
                    fillColor = "green";
                  } else if (mapItem.assigned && mapItem.assigned.length > 0) {
                    fillColor = "yellow";
                  } else {
                    fillColor = "red";
                  }
                }
                return (
                  <Polygon
                    fillColor={fillColor}
                    key={mapItem.id}
                    positions={mapItem.geometry.coordinates}
                  ></Polygon>
                );
              })}
              {false &&
                !letterboxing &&
                maps?.map((mapItem) => {
                  const unknockedDoors =
                    (mapItem?.addressCount ?? 0) - (mapItem?.knockedCount ?? 0);
                  const backgroundColor =
                    unknockedDoors <= 10
                      ? "#77DD77"
                      : unknockedDoors <= 50
                        ? "#F8C57C"
                        : unknockedDoors <= 100
                          ? "#FDFD96"
                          : "#FFFFFF";
                  const icon = new DivIcon({
                    html: `<div
                    style="width: fit-content;
                    background-color: ${backgroundColor};
                    padding: 4px;
                    border: 1px solid black;"
                  >${mapItem.name}<br/>${unknockedDoors} unknocked.</div>`,
                  });
                  const centre: LatLngTuple = [
                    parseFloat(mapItem.latitude as string),
                    parseFloat(mapItem.longitude as string),
                  ];
                  // polygon.bindTooltip("My polygon", {permanent: true, direction:"center"})
                  return (
                    <Marker
                      key={mapItem.id + "marker"}
                      position={centre}
                      icon={icon}
                    />
                  );
                })}
              {(true || letterboxing) &&
                maps.map((mapItem) => {
                  const letterboxed = !!mapItem.letterboxedTimestamp;
                  // const backgroundColor = unknockedDoors <= 10 ? '#77DD77' : unknockedDoors <= 50 ? '#F8C57C' : unknockedDoors <= 100 ? '#FDFD96' : "#FFFFFF";
                  // const backgroundColor = letterboxed ? 'green' : 'red'
                  const icon = new DivIcon({
                    html: `<div
                    style="width: fit-content;
                    background-color: ${
                      // letterboxing
                      //   ? !!mapItem.letterboxedTimestamp
                      //     ? "#b3eb9d"
                      //     : "#e37f78"
                      //   : "#a7b9fa"
                      (() => {
                        if (mapIsCompleted(mapItem)) {
                          return "#b3eb9d";
                        } else if (
                          mapItem.assigned &&
                          mapItem.assigned.length > 0
                        ) {
                          return "#fbec5d";
                        } else {
                          return "#e37f78";
                        }
                      })()
                    };
                    padding: 4px;
                    border: 1px solid black;"
                  >${mapItem.name} (${mapItem.addressCount} doors)</div>`,
                  });
                  const centre: LatLngTuple = [
                    parseFloat(mapItem.latitude as string),
                    parseFloat(mapItem.longitude as string),
                  ];
                  // polygon.bindTooltip("My polygon", {permanent: true, direction:"center"})
                  return (
                    <Marker
                      key={mapItem.id + "marker"}
                      position={centre}
                      icon={icon}
                    >
                      <Popup>
                        <MapAssigner map={mapItem} />
                      </Popup>
                    </Marker>
                  );
                })}
            </>
          </MapContainer>
        )}
      </Box>
    </Paper>
  );
};

export default MapBrowser;
