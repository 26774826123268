import { MapContainer, TileLayer, Marker, Polygon } from "react-leaflet";
import { Map as LeafletMap, LatLngTuple, DivIcon } from "leaflet";
import "../App.css";
import {
  IAddress,
  IAddressEntry,
  AddressCollectionI,
  IMap,
  IBuilding,
  ICampaign,
} from "../types";
import { updateBuilding } from "../utils/api/buildings";
import { Box, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import Loader from "../components/Loader";
import { useSnackbar } from "notistack";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import { updateCompletionStatus } from "../utils/api/maps";
import useStore from "../utils/store";
import KnockingForm from "../components/KnockingForm";
import ModalDialog from "../components/layout/ModalDialog";
import AddressMarker from "../components/map/AddressMarker";
import BuildingMarker from "../components/map/BuildingMarker";
import WarningIcon from "../components/misc/WarningIcon";
import { openKnockingFormModal } from "../utils";
import MapProgress from "../components/misc/MapProgress";
import UnansweredFilterModal from "../components/UnansweredFilterModal";
import FilterButton from "../components/FilterButton";

const addressEntryIsAddress = (
  addressEntry: IAddressEntry,
): addressEntry is IAddress => !(addressEntry as IBuilding).addresses;

interface Props {
  map: IMap;
  campaign: ICampaign;
  addresses: AddressCollectionI;
  reloadAddresses: () => Promise<void>;
  userLocation: { position: LatLngTuple; heading: any };
  reloadMap: () => Promise<void>;
}
const Knocking = ({
  map,
  campaign,
  addresses,
  reloadAddresses,
  userLocation,
  reloadMap,
}: Props) => {
  const [selectedAddress, setSelectedAddress] = useState<IAddress | undefined>(
    undefined,
  );

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const submissionMode = useStore((state) => state.submissionMode);

  const [runConfetti, setRunConfetti] = useState(false);
  const [recycleConfetti, setRecycleConfetti] = useState(false);
  const { width, height } = useWindowSize();

  const popupRef = useRef<LeafletMap>(null);
  const closePopup = () => popupRef.current?.closePopup();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      reloadMap();
      reloadAddresses();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const centre: LatLngTuple = [
    parseFloat(map.latitude as string),
    parseFloat(map.longitude as string),
  ];

  const completedAddresses = addresses.reduce((acc, addressEntry) => {
    if (
      addressEntryIsAddress(addressEntry) &&
      addressEntry.last_knock_timestamp
    ) {
      return acc + 1;
    } else if (!addressEntryIsAddress(addressEntry)) {
      return (
        acc +
        addressEntry.addresses.reduce((acc, address) => {
          if (address.last_knock_timestamp) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0)
      );
    } else {
      return acc;
    }
  }, 0);

  const addressCount = addresses.reduce((acc, addressEntry) => {
    if (addressEntryIsAddress(addressEntry)) {
      return acc + 1;
    } else {
      return (addressEntry as IBuilding).status
        ? acc
        : acc + addressEntry.addresses.length;
    }
  }, 0);

  const completionPercentage = Math.round(
    (completedAddresses / addressCount) * 100,
  );

  const handleLetterboxingClick = async () => {
    const letterboxed = map.letterboxedTimestamp ? false : true;

    setLoading(true);
    try {
      await updateCompletionStatus(
        map.id,
        campaign.id,
        "Letterboxing",
        letterboxed,
      );
    } catch (e) {
      setLoading(false);
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
      return;
    }

    await reloadMap();
    setLoading(false);

    if (letterboxed) {
      setRunConfetti(true);
      setRecycleConfetti(true);
      setTimeout(() => {
        setRecycleConfetti(false);
      }, 3000);
    }

    enqueueSnackbar("Letterboxing status updated.", { variant: "success" });
  };

  const handleBuildingStatusChange = async (
    status: string,
    building_id: number,
  ) => {
    setLoading(true);
    const postBuilding: IBuilding = {
      building_id,
      status,
    } as IBuilding;

    setLoading(true);
    try {
      await updateBuilding(postBuilding);
    } catch (e) {
      setLoading(false);
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
      return;
    }
    reloadAddresses();
    setLoading(false);

    enqueueSnackbar("Building status updated.", { variant: "success" });
  };

  if (
    (addresses.length === 0 && submissionMode === "Doorknocking") ||
    !centre ||
    isNaN(centre[0])
  )
    return <></>;
  return (
    <Box
      id="knockify-knocking-screen"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {runConfetti && (
        <div style={{ zIndex: 9999 }}>
          <Confetti
            width={width}
            height={height}
            recycle={recycleConfetti}
            numberOfPieces={500}
            run={runConfetti}
          />
        </div>
      )}
      {loading && <Loader />}
      <MapContainer
        className="z-10"
        ref={popupRef}
        center={centre}
        zoom={16}
        scrollWheelZoom={true}
        zoomControl={false}
      >
        <>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom={22}
            maxNativeZoom={19}
          />
          <Polygon
            positions={map.geometry.coordinates}
            pathOptions={{
              color: "red",
              opacity: 0.3,
              fillOpacity: 0.05,
            }}
          />
          {submissionMode === "Doorknocking" &&
            addresses.map((addressEntry) => {
              return addressEntryIsAddress(addressEntry) ? (
                <AddressMarker
                  key={addressEntry.id}
                  address={addressEntry}
                  setSelectedAddress={setSelectedAddress}
                  closePopup={closePopup}
                  reloadAddresses={reloadAddresses}
                />
              ) : (
                <BuildingMarker
                  key={addressEntry.building_id}
                  building={addressEntry}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                  handleBuildingStatusChange={handleBuildingStatusChange}
                  closePopup={closePopup}
                  reloadAddresses={reloadAddresses}
                />
              );
            })}
          <Marker
            position={userLocation.position}
            zIndexOffset={500}
            icon={
              new DivIcon({
                html: `<div
                  style="
                    background: #ff6961;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    box-shadow: 2px 2px 5px black;
                    border: 1px solid black;
                    margin-left: -5px;
                    margin-top: -5px;
                    "
                  ></div>`,
                bgPos: [100, 100],
              })
            }
          />
        </>
      </MapContainer>
      {submissionMode === "Doorknocking" && (
        <>
          <FilterButton closePopup={closePopup} />
          <button
            className="absolute left-2 bottom-14 z-40 drop-shadow-md"
            onClick={() => {
              setSelectedAddress(undefined);
              closePopup();
              openKnockingFormModal();
            }}
          >
            <PlusCircleIcon className="h-12 w-12 text-accent" />
          </button>

          <MapProgress
            completionPercentage={completionPercentage}
            map={map}
            campaign={campaign}
            reloadMap={reloadMap}
            className="absolute right-2 bottom-16 z-40"
          />
        </>
      )}
      {submissionMode === "Letterboxing" && (
        <Button
          variant="contained"
          color={map.letterboxedTimestamp ? "success" : "warning"}
          sx={{
            position: "absolute",
            bottom: "64px",
            left: "8px",
            zIndex: 1200,
          }}
          onClick={handleLetterboxingClick}
        >
          {map.letterboxedTimestamp ? "Letterboxed" : "Not letterboxed"}
        </Button>
      )}

      <ModalDialog id="doorknocking_form">
        <>
          {selectedAddress === undefined && (
            <div className="alert alert-warning">
              <WarningIcon />
              You are submitting a response for a custom address. If you want to
              submit a response for an address that appears on your map, please
              do so by clicking on that marker.
            </div>
          )}

          <KnockingForm
            key={selectedAddress?.id}
            address={selectedAddress}
            mapId={map.id}
            closePopup={closePopup}
            reloadAddresses={reloadAddresses}
          />
        </>
      </ModalDialog>
      <UnansweredFilterModal />
    </Box>
  );
};

export default Knocking;
