import useStore from "../utils/store";
import { Formik } from "formik";
import * as Yup from "yup";
import FormQuestionField from "./form/FormQuestionField";
import { IAddress, ISurveyQuestion } from "../types";
import Accordion from "./layout/Accordion";
import { submitResponse } from "../utils/api/responses";
import { formatAddress } from "../utils/addressUtils";
import { useSnackbar } from "notistack";
import { closeKnockingFormModal } from "../utils";

const getInitialValue = (question: ISurveyQuestion) => {
  if (question.default_value) return question.default_value;
  if (question.data_type === "Boolean") return false;
  if (question.data_type === "Int") return 3;
};

const initializeQuestionObj = ({
  label,
  id,
  data_type,
  disabled,
}: IStandardQuestion): ISurveyQuestion => ({
  label,
  id,
  data_type,
  disabled,
  html_type: "Text",
  default_value: null,
  options: [],
  help_pre: null,
  help_post: null,
  text_length: null,
});

interface IStandardQuestion {
  label: string;
  id: string;
  data_type: "String";
  disabled?: boolean;
}

interface Props {
  address?: IAddress;
  mapId: number;
  closePopup: () => void;
  reloadAddresses: () => Promise<void>;
}

const KnockingForm = ({
  address: providedAddress,
  mapId,
  closePopup,
  reloadAddresses,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const survey = useStore((state) => state.survey);
  const selectedCampaign = useStore((state) => state.selectedCampaign);

  // TODO figure out type
  const initialValues: any = {
    address: providedAddress ? formatAddress(providedAddress) : "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
  };

  survey?.questions.forEach((question) => {
    initialValues[question.id] = getInitialValue(question);
  });

  const validationSchema = Yup.object({
    address: Yup.string().required("Please enter an address."),
  });

  const onSubmit = async (values: any) => {
    const surveyValues: any = {};
    survey?.questions.forEach((question) => {
      surveyValues[`${question["custom_group_id:name"]}.${question.name}`] =
        values[question.id];
    });

    const standardValues = {
      first_name: values.first_name,
      last_name: values.last_name,
      phone_number: values.phone_number,
      email: values.email,
      address: providedAddress ? providedAddress : values.address,
    };
    const payload = {
      surveyValues: surveyValues,
      standardValues: standardValues,
      campaignId: selectedCampaign?.id,
    };

    try {
      await submitResponse(payload, mapId);
      await reloadAddresses();

      closePopup();
      closeKnockingFormModal();

      enqueueSnackbar("Response submitted.", { variant: "success" });
    } catch (error) {
      console.error(error);
      const message =
        (error as any).response?.data?.message ??
        "Something went wrong. Please try again.";
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  const addressQuestion: IStandardQuestion = {
    label: "Address",
    id: "address",
    data_type: "String",
    disabled: !!providedAddress,
  };

  const personalDetailsQuestions: IStandardQuestion[] = [
    {
      label: "First Name",
      id: "first_name",
      data_type: "String",
    },
    {
      label: "Last Name",
      id: "last_name",
      data_type: "String",
    },
    {
      label: "Phone Number",
      id: "phone_number",
      data_type: "String",
    },
    {
      label: "Email",
      id: "email",
      data_type: "String",
    },
  ];

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <div className="flex flex-col gap-4">
            <FormQuestionField
              key={addressQuestion.id}
              question={initializeQuestionObj(addressQuestion)}
            />
            <Accordion groupName="knocking-form" label="Survey questions">
              {survey?.questions.map((question) => (
                <FormQuestionField
                  key={question.id + providedAddress?.id}
                  question={question}
                />
              ))}
            </Accordion>
            <Accordion groupName="knocking-form" label="Personal details">
              {personalDetailsQuestions.map((question) => (
                <FormQuestionField
                  key={question.id}
                  question={initializeQuestionObj(question)}
                />
              ))}
            </Accordion>
            <div className="flex justify-end">
              <button className="btn btn-primary" onClick={formik.submitForm}>
                Submit
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default KnockingForm;
