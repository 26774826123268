import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import { useState } from "react";
import { IAddress } from "../../types";
import { formatAddress } from "../../utils/addressUtils";
import { pink } from "@mui/material/colors";
import WarningIcon from "../misc/WarningIcon";
import {
  XCircleIcon,
  ChatBubbleLeftRightIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import {
  submitNonAnswerResponse,
  submitNonCommittalResponse,
} from "../../utils/api/responses";
import useStore from "../../utils/store";
import { openKnockingFormModal } from "../../utils";
import { useSnackbar } from "notistack";
import AddressPopupHelpModal from "./AddressPopupHelpModal";

interface Props {
  address: IAddress;
  closePopup: () => void;
  reloadAddresses: () => Promise<void>;
}

const AddressPopupContent = ({
  address,
  closePopup,
  reloadAddresses,
}: Props) => {
  const selectedCampaign = useStore((state) => state.selectedCampaign);
  const { enqueueSnackbar } = useSnackbar();

  const last_knock = address.last_knock_timestamp
    ? new Date(address.last_knock_timestamp)
    : null;
  const last_knock_time = last_knock
    ? last_knock.toLocaleTimeString("en-AU")
    : null;
  const last_knock_date = last_knock
    ? last_knock.toLocaleDateString("en-AU") ===
      new Date().toLocaleDateString("en-AU")
      ? "today"
      : last_knock.toLocaleDateString("en-AU")
    : null;

  const handleModalOpen = () => {
    (window as any).address_popup_help_modal.showModal();
  };

  const submitNonCommittal = async () => {
    try {
      await submitNonCommittalResponse(
        address.id,
        selectedCampaign?.id as number,
        address.map_id,
      );
      await reloadAddresses();
      // address.last_knock_timestamp = Date();
      closePopup();
      enqueueSnackbar("Response submitted.", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Error submitting response.", { variant: "error" });
    }
  };

  const submitNoAnswer = async () => {
    try {
      await submitNonAnswerResponse(
        address.id,
        selectedCampaign?.id as number,
        address.map_id,
      );
      await reloadAddresses();
      // address.last_knock_timestamp = Date();
      closePopup();
      enqueueSnackbar("Response submitted.", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Error submitting response.", { variant: "error" });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <h6 className="font-bold text-lg">{formatAddress(address)}</h6>

      {/*
        <div className="text-base">
          {address.electorCount} enrolled{" "}
          {address.electorCount > 1 ? "voters" : "voter"} at this address.
        </div>
      */}

      {address.last_knock_timestamp && (
        <div className="alert alert-warning flex">
          <WarningIcon />
          This address has already been knocked at {last_knock_time}{" "}
          {last_knock_date}
        </div>
      )}
      <div className="flex justify-between">
        <div className="flex gap-2">
          <button className="btn btn-error" onClick={submitNoAnswer}>
            <XCircleIcon className="h-6 w-6" />
          </button>
          <button className="btn btn-neutral" onClick={submitNonCommittal}>
            <PsychologyAltIcon />
          </button>
          <button className="btn btn-success" onClick={openKnockingFormModal}>
            <ChatBubbleLeftRightIcon className="h-6 w-6" />
          </button>
          <button
            className="btn btn-solid btn-warning"
            onClick={handleModalOpen}
          >
            <QuestionMarkCircleIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
      <AddressPopupHelpModal />
    </div>
  );
};

export default AddressPopupContent;
