import clsx from "clsx";
import { DivIcon, Icon, LatLngTuple } from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { IAddress } from "../../types";
import AddressPopupContent from "./AddressPopupContent";
import useStore from "../../utils/store";

interface Props {
  address: IAddress;
  setSelectedAddress: (address?: IAddress) => void;
  closePopup: () => void;
  reloadAddresses: () => Promise<void>;
}

const AddressMarker = ({
  address,
  setSelectedAddress,
  closePopup,
  reloadAddresses,
}: Props) => {
  const unansweredThreshold = useStore((state) => state.unansweredThreshold);

  const position: LatLngTuple = [
    parseFloat(address.latitude),
    parseFloat(address.longitude),
  ];

  // const grayedOut = address.last_knock_timestamp;
  const grayedOut =
    address.conversationCount > 0 ||
    address.responseCount >= unansweredThreshold;
  // const icon: DivIcon = new DivIcon({
  //   html: `
  //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" >
  //       <path fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
  //     </svg>
  //   `,
  //   className: clsx("h-12 w-12 text-accent-focus drop-shadow-md", {
  //     "text-base-300": grayedOut,
  //   }),
  //   iconAnchor: [20, 37],
  //   iconSize: [40, 40],
  // });

  const icon: Icon = new Icon({
    iconUrl: grayedOut
      ? "sites/default/files/images/marker-lightgray-40.png"
      : "sites/default/files/images/marker-purple-40.png",
    shadowUrl: "sites/default/files/images/marker-shadow.png",
    iconAnchor: [20, 37],
    iconSize: [40, 40],
    shadowSize: [41, 41],
    shadowAnchor: [13, 40],
  });

  return (
    <Marker
      position={position}
      key={`address_${address.id}`}
      icon={icon}
      eventHandlers={{
        popupopen: () => setSelectedAddress(address),
        popupclose: () => setSelectedAddress(undefined),
      }}
    >
      <Popup>
        <AddressPopupContent
          address={address}
          closePopup={closePopup}
          reloadAddresses={reloadAddresses}
        />
      </Popup>
    </Marker>
  );
};

export default AddressMarker;
