import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import {
  ChatBubbleLeftRightIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";

const Tutorial = () => {
  return (
    <article className="prose">
      <h2>Getting started</h2>
      <p>
        For each door knock, a coordinator will assign you one or more maps.
      </p>
      <p>
        Select this map from the home screen. If you can't see this map in the
        list of assigned maps, then you can select it from the dropdown.
      </p>
      <p>
        Your door knocking map will appear, along with markers that indicate
        addresses inhabited by one or more registered voters.
      </p>
      <h2>Logging interactions</h2>
      <p>
        Door knock each of the addresses indicated by a marker. (Do not knock on
        every door within the map area). Each marker indicates an address
        inhabited by one or more registered voters.
      </p>
      <ul>
        <li>Select an address by tapping a marker.</li>
        <li>Door knock this address.</li>
        <li>Log the result by selecting one of three options.</li>
      </ul>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4 items-center">
          <button className="btn btn-error">
            <XCircleIcon className="h-6 w-6" />
          </button>
          <div>Hit this button if nobody answered the door.</div>
        </div>

        <div className="flex gap-4 items-center">
          <button className="btn btn-neutral">
            <PsychologyAltIcon />
          </button>
          <div>
            Hit this button if you had a brief and noncommittal conversation
            (eg. the person didn't have time to talk or was not interested)
          </div>
        </div>

        <div className="flex gap-4 items-center">
          <button className="btn btn-success">
            <ChatBubbleLeftRightIcon className="h-6 w-6" />
          </button>
          <div>Hit this button if you had a proper conversation.</div>
        </div>
      </div>
      <h2>Other Tips</h2>
      {/*
      <p>
        If you want to experiment with or demonstrate submitting responses to
        the app, first turn on practice mode via the settings tab. Don’t forget
        to turn practice mode off before starting your actual door knocking.
      </p>
      */}
      <p>
        If needed, you can log a door knocking response for an address not
        represented by a marker on the map by clicking on the + sign at the
        bottom left of the map screen.
      </p>
    </article>
  );
};

export default Tutorial;
