import { Box, List, Typography } from "@mui/material";
import Accordion from "../components/layout/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStore, { signOut } from "../utils/store";
import Toggle from "../components/form/Toggle";
import React from "react";
import Select from "../components/form/Select";
import { useNavigate } from "react-router-dom";

const THEMES = [
  "light",
  "dark",
  "cupcake",
  "bumblebee",
  "emerald",
  "corporate",
  "synthwave",
  "retro",
  "cyberpunk",
  "valentine",
  "halloween",
  "garden",
  "forest",
  "aqua",
  "lofi",
  "pastel",
  "fantasy",
  "wireframe",
  "black",
  "luxury",
  "dracula",
  "cmyk",
  "autumn",
  "business",
  "acid",
  "lemonade",
  "night",
  "coffee",
  "winter",
];

const RELEASES = [
  {
    name: "3.1.0",
    notes: [
      "Allow filtering addresses by number of unanswered knock attempts (see the funnel icon in the bottom left of the knocking screen).",
    ],
  },
  {
    name: "3.0.0",
    notes: ["New council ward maps"],
  },
  {
    name: "2.1.0",
    notes: [
      "Visual overhaul",
      "Theme selection",
      "Display assigned doorknocking maps and sync responses with CiviCRM",
      "Display map completion progress and allow marking maps as completed",
      "Other minor changes",
    ],
  },
  {
    name: "2.0.0",
    notes: [
      "Campaign-specific data",
      "Display assigned maps",
      "Standalone authentication",
      "Other minor changes",
    ],
  },
  {
    name: "1.6.0",
    notes: [
      "Add user-specific stats. (These have only been collected since the 12th of October.)",
      "Other minor changes.",
    ],
  },
  {
    name: "1.5.0",
    notes: ["Add letterboxing mode."],
  },
  {
    name: "1.4.0",
    notes: [
      "Add tutorial (hit the yellow question mark in the top bar!).",
      "Add region figures to the stats screen.",
    ],
  },
  {
    name: "1.3.0",
    notes: [
      "Add marker to track user location on map.",
      "Add buttons to mark multi-unit buildings as 'inaccessible' or 'letterbox only'. This is for apartment buildings where you can't get to anyone's door.",
      "Other minor changes.",
    ],
  },
  {
    name: "1.2.0",
    notes: [
      "Add release notes. 🙃",
      "Allow zooming in further on the map.",
      "Load responses every 60 seconds (so you can see which doors other volunteers have knocked on your map).",
      "Display more detailed stats.",
      "Tweak response form.",
      "Other minor changes.",
    ],
  },
];

const Settings = () => {
  const geolocation = useStore((state) => state.geolocation);
  const toggleGeolocation = useStore((state) => state.toggleGeolocation);
  // const practiceMode = useStore((state) => state.practiceMode);
  // const togglePracticeMode = useStore((state) => state.togglePracticeMode);
  const theme = useStore((state) => state.theme);
  const setTheme = useStore((state) => state.setTheme);
  const reload = () => window.location.reload();

  return (
    <div className="my-4">
      <div className="card card-compact border-2 border-primary">
        <div className="card-body">
          {/*
      <Toggle
        label="Toggle practice mode"
        helpText="In practice mode you can freely submit responses to get familiar with the app."
        id="practice-mode"
        value={practiceMode}
        onChange={togglePracticeMode}
      />
      */}
          <Toggle
            label="Toggle geolocation"
            helpText="This will show your location with a red marker on the map."
            id="toggle-geolocation"
            value={geolocation}
            onChange={toggleGeolocation}
            labelBefore
          />
          <div className="divider" />
          <Select
            label="Theme"
            labelAfter="Select a colour theme. If in doubt, Pastel is a safe bet."
            id="theme"
            value={theme}
            onChange={(value) => setTheme(value as string)}
            options={THEMES.map((theme) => ({
              label: theme,
              value: theme.toLowerCase(),
            }))}
            displayLabelInline
          />
          <div className="divider" />
          <button
            className="btn btn-warning w-fit"
            onClick={() => {
              signOut();
              reload();
            }}
          >
            Sign out
          </button>
        </div>
      </div>
      <div className="mt-4" />

      <Accordion label="Release Notes">
        <div className="flex flex-col gap-4">
          {RELEASES.map((release, index) => (
            <div
              className="card card-compact border-2 border-primary"
              key={index}
            >
              <div className="card-body">
                <h3 className="card-title">
                  Release {release.name} {index === 0 && " (current)"}
                </h3>
                <ul className="list-disc">
                  {release.notes.map((note, index) => (
                    <li className="ml-4" key={index}>
                      {note}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Accordion>
    </div>
  );
};

export default Settings;
