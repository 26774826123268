import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { produce } from "immer";
import {
  ICampaign,
  IColorMode,
  IMap,
  ISubmissionMode,
  ISurvey,
  IUser,
} from "../types";
import api from "./api/base";
import { verify } from "./api/auth";
import { getCampaignSurveyById } from "./api/campaigns";

interface IAction {
  isAuthed: () => boolean;
  setUser: (user: IUser) => void;
  loadProfile: () => void;
  togglePracticeMode: () => void;
  toggleGeolocation: () => void;
  // setSubmissionMode: (mode: ISubmissionMode) => void;
  setColorMode: (mode: IColorMode) => void;
  setSelectedCampaign: (campaign?: ICampaign) => void;
  setSelectedMapId: (mapId?: number) => void;
  setSelectedMap: (map?: IMap) => void;
  setTheme: (theme: string) => void;
  setUnansweredThreshold: (threshold: number) => void;
  // setSurvey: (survey: any) => void;
}

interface IInitState {
  token: string;
  user?: IUser;
  practiceMode: boolean;
  geolocation: boolean;
  submissionMode: ISubmissionMode;
  colorMode: IColorMode;
  selectedCampaign?: ICampaign;
  selectedMapId?: number;
  selectedMap?: IMap;
  theme: string;
  survey?: ISurvey;
  unansweredThreshold: number;
}

const initState: IInitState = {
  token: "",
  practiceMode: false,
  geolocation: true,
  submissionMode: "Letterboxing",
  colorMode: "light",
  theme: "pastel",
  unansweredThreshold: 1,
};

const useStore = create<IInitState & IAction>()(
  devtools(
    persist(
      (set, get) => ({
        ...initState,

        isAuthed: () => get().token !== "",

        togglePracticeMode: () => {
          set(
            produce((state) => {
              state.practiceMode = !state.practiceMode;
            }),
          );
        },

        toggleGeolocation: () => {
          set(
            produce((state) => {
              state.geolocation = !state.geolocation;
            }),
          );
        },

        setUser: (user: IUser) => {
          set(
            produce((state) => {
              state.user = user;
            }),
          );
        },

        loadProfile: async () => {
          try {
            const response = await fetch("/api/profile", {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${get().token}`,
              },
            });

            if (response.status === 200) {
              const user = await response.json();
              get().setUser(user);
            }
          } catch (error) {
            // TODO handle error
            console.error(error);
          }
        },

        // setSubmissionMode: (mode) => {
        //   set(
        //     produce((state) => {
        //       state.submissionMode = mode;
        //     })
        //   );
        // },

        setColorMode: (mode) => {
          set(
            produce((state) => {
              state.colorMode = mode;
            }),
          );
        },

        setSelectedCampaign: async (campaign) => {
          set(
            produce((state) => {
              state.selectedCampaign = campaign;
              state.selectedMapId = campaign ? state.selectedMapId : undefined;
              state.selectedMap = campaign ? state.selectedMap : undefined;
              state.submissionMode = campaign ? campaign.type : undefined;
            }),
          );
          // If Doorknocking campaign then load survey
          campaign?.type === "Doorknocking" && (await loadSurvey(campaign.id));
        },

        setSelectedMapId: (mapId) => {
          set(
            produce((state) => {
              state.selectedMapId = mapId;
            }),
          );
        },

        setSelectedMap: (map) => {
          set(
            produce((state) => {
              state.selectedMap = map;
            }),
          );
        },

        setTheme: (theme) => {
          set(
            produce((state) => {
              state.theme = theme;
            }),
          );
          // document.querySelector("html")?.setAttribute("data-theme", theme);
        },

        setUnansweredThreshold: (threshold) => {
          set(
            produce((state) => {
              state.unansweredThreshold = threshold;
            }),
          );
        },
      }),

      {
        name: "main-storage",
        version: 20240524002,
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(
              ([key]) => key !== "user" && key !== "survey",
            ),
          ),
      },
    ),
  ),
);

export const setToken = (token: string) => {
  useStore.setState({ token });
  api.updateToken(token);
  localStorage.setItem("token", token);
};

export const signOut = () => {
  useStore.setState({ user: undefined, token: "" });
  api.clearToken();
  localStorage.removeItem("token");
};

export const getToken = () => {
  return useStore.getState().token;
};

export const verifyToken = async (
  token: string | null,
  handleSuccess?: () => void,
  handleError?: () => void,
) => {
  const clear = () => {
    useStore.setState({ user: undefined, token: "" });
    handleError && handleError();
  };

  try {
    if (token) {
      const user = await verify(token);
      // console.log("returned user", user);
      if (user) {
        // console.log("user", user);
        setToken(token);
        useStore.setState({ user });
        initialiseData();
        handleSuccess && handleSuccess();
        return;
      }
    } else {
      clear();
    }
  } catch (_e) {
    clear();
  }
};

const loadSurvey = async (id: number) => {
  const survey = await getCampaignSurveyById(id);
  useStore.setState({ survey });
};

const initialiseData = async () => {
  const selectedCampaignId = useStore.getState().selectedCampaign?.id;
  if (selectedCampaignId) await loadSurvey(selectedCampaignId);
  const selectedCampaign = useStore.getState().selectedCampaign;
  if (selectedCampaign)
    useStore.setState({ submissionMode: selectedCampaign.type });
};

export default useStore;
