import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DoorFrontIcon from "@mui/icons-material/DoorFront";
import { DivIcon, Icon, LatLngTuple } from "leaflet";
import React from "react";
import { Marker, Popup } from "react-leaflet";
import { IAddress, IBuilding } from "../../types";
import { formatAddress, formatBuildingAddress } from "../../utils/addressUtils";
import AddressPopupContent from "./AddressPopupContent";
import clsx from "clsx";
import useStore from "../../utils/store";

interface Props {
  building: IBuilding;
  selectedAddress?: IAddress;
  setSelectedAddress: (address?: IAddress) => void;
  handleBuildingStatusChange: (newStatus: string, building_id: number) => void;
  closePopup: () => void;
  reloadAddresses: () => Promise<void>;
}

const BuildingMarker = ({
  building,
  selectedAddress,
  setSelectedAddress,
  handleBuildingStatusChange,
  closePopup,
  reloadAddresses,
}: Props) => {
  const unansweredThreshold = useStore((state) => state.unansweredThreshold);

  const status = building.status;

  const position: LatLngTuple = [
    parseFloat(building.latitude),
    parseFloat(building.longitude),
  ];

  const unknockedDoors = building.addresses.some(
    (address) =>
      !(
        address.conversationCount > 0 ||
        address.responseCount >= unansweredThreshold
      ),
  );

  const grayedOut =
    !unknockedDoors || status === "inaccessible" || status === "letterbox-only";
  // const icon: DivIcon = new DivIcon({
  //   html: `
  //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
  //       <path fill-rule="evenodd" d="M4.5 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5h-.75V3.75a.75.75 0 000-1.5h-15zM9 6a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm-.75 3.75A.75.75 0 019 9h1.5a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM9 12a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm3.75-5.25A.75.75 0 0113.5 6H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM13.5 9a.75.75 0 000 1.5H15A.75.75 0 0015 9h-1.5zm-.75 3.75a.75.75 0 01.75-.75H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM9 19.5v-2.25a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-4.5A.75.75 0 019 19.5z" clip-rule="evenodd" />
  //     </svg>
  //   `,
  //   className: clsx("h-12 w-12 text-accent-focus drop-shadow-md", {
  //     "text-base-300": grayedOut,
  //   }),
  //   iconAnchor: [20, 37],
  //   iconSize: [40, 40],
  // });

  const icon: Icon = new Icon({
    iconUrl: grayedOut
      ? "sites/default/files/images/marker-lightgray-40.png"
      : "sites/default/files/images/marker-purple-40.png",
    shadowUrl: "sites/default/files/images/marker-shadow.png",
    iconAnchor: [20, 37],
    iconSize: [40, 40],
    shadowSize: [41, 41],
    shadowAnchor: [13, 40],
  });

  const handleStatusChange = async (newStatus: string) => {
    if (newStatus === status) {
      newStatus = "";
    }
    handleBuildingStatusChange(newStatus, building.building_id);
  };

  return (
    <Marker
      position={position}
      key={"building" + building.building_id}
      icon={icon}
      eventHandlers={{
        popupclose: () => {
          setSelectedAddress(undefined);
        },
      }}
    >
      <Popup>
        {selectedAddress ? (
          <AddressPopupContent
            address={selectedAddress}
            closePopup={closePopup}
            reloadAddresses={reloadAddresses}
          />
        ) : (
          <>
            <h6 className="font-bold text-lg">
              {formatBuildingAddress(building.addresses[0])}
            </h6>
            <div className="text-base">
              {building.addresses.length} units in this building.
            </div>
            <div className="join join-vertical">
              <button
                onClick={() => handleStatusChange("inaccessible")}
                className={clsx("btn join-item btn-accent", {
                  "btn-active": status === "inaccessible",
                  "opacity-50": status !== "inaccessible",
                })}
              >
                Inaccessible
              </button>
              <button
                onClick={() => handleStatusChange("letterbox-only")}
                className={clsx("btn join-item btn-accent", {
                  "btn-active": status === "letterbox-only",
                  "opacity-50": status !== "letterbox-only",
                })}
              >
                Accessible for letterboxing only
              </button>
            </div>

            <List>
              {building.addresses.map((address) => {
                const disabled =
                  address.conversationCount > 0 ||
                  address.responseCount >= unansweredThreshold ||
                  status === "inaccessible" ||
                  status === "letterbox-only";
                return (
                  <React.Fragment key={address.id}>
                    <ListItem
                      key={address.id}
                      onClick={() => setSelectedAddress(address)}
                      disablePadding
                    >
                      <ListItemIcon>
                        <DoorFrontIcon
                          className={clsx({
                            "text-primary-content/20": disabled,
                            "text-primary-content": !disabled,
                          })}
                        />
                      </ListItemIcon>
                      <ListItemText primary={formatAddress(address)} />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          </>
        )}
      </Popup>
    </Marker>
  );
};

export default BuildingMarker;
