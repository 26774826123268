import { useField } from "formik";
import { ISurveyQuestion } from "../../types";
import Slider from "./Slider";
import CheckBox from "./CheckBox";
import TextInput from "./TextInput";
import clsx from "clsx";

// TODO fix types
interface Props {
  question: ISurveyQuestion;
}

const FormQuestionField = ({ question }: Props) => {
  const [_field, meta, helpers] = useField(question.id);
  const { value } = meta;
  const { setValue } = helpers;

  const {
    data_type: dataType,
    html_type: htmlType,
    label,
    options,
    help_pre: preHelpText,
    help_post: postHelpText,
    disabled,
  } = question;

  return (
    <div
      className={clsx("form-control", {
        "flex-row items-center gap-4": dataType === "Boolean",
      })}
    >
      {label && (
        <label className="label" htmlFor={question.id}>
          <span className="label-text">{label}</span>
        </label>
      )}

      {preHelpText && (
        <label className="label">
          <span className="label-text-alt">{preHelpText}</span>
        </label>
      )}

      {dataType === "String" || dataType === "Memo" ? (
        <TextInput
          id={question.id}
          value={value}
          onChange={(value) => setValue(value)}
          disabled={disabled}
        />
      ) : null}

      {dataType === "Int" && htmlType === "Radio" && (
        <Slider
          id={question.id}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setValue(parseInt(event.target.value))
          }
          options={options}
        />
      )}

      {dataType === "Boolean" && (
        <CheckBox
          id={question.id}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setValue(event.target.checked)
          }
        />
      )}

      {postHelpText && (
        <label className="label">
          <span className="label-text-alt">{preHelpText}</span>
        </label>
      )}

      {meta.error && (
        <div className="label">
          <span className="label-text-alt text-error">{meta.error}</span>
        </div>
      )}
    </div>
  );
};

export default FormQuestionField;
