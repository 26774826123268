import clsx from "clsx";

interface Props {
  label?: string;
  labelAfter?: string;
  id: string;
  value?: string | number;
  onChange: (value: string | number) => void;
  options: { label: string; value: string | number }[];
  disabled?: boolean;
  placeholder?: string;
  displayLabelInline?: boolean;
}

const Select = ({
  label,
  labelAfter,
  id,
  value,
  onChange,
  options,
  disabled = false,
  placeholder,
  displayLabelInline = false,
}: Props) => {
  // console.log("select value", value);
  return (
    <div className="form-control text-base-content">
      <div
        className={clsx({
          "flex flex-row gap-4": displayLabelInline,
        })}
      >
        {label && (
          <label className="label" htmlFor={id}>
            <span className="label-text">{label}</span>
          </label>
        )}
        <select
          id={id}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          disabled={disabled}
          className="select select-bordered font-normal w-full"
        >
          {placeholder && (
            <option selected disabled>
              {placeholder}
            </option>
          )}
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      {labelAfter && (
        <label className="label" htmlFor={id}>
          <span className="label-text-alt">{labelAfter}</span>
        </label>
      )}
    </div>
  );
};

export default Select;
