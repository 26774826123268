import ModalDialog from "./layout/ModalDialog";
import useStore from "../utils/store";
import Slider from "./form/Slider";

const UnansweredFilterModal = () => {
  const unansweredThreshold = useStore((state) => state.unansweredThreshold);
  const setUnansweredThreshold = useStore(
    (state) => state.setUnansweredThreshold,
  );

  return (
    <ModalDialog id="filter_modal">
      <div className="flex flex-col gap-4">
        <div className="prose">
          <h2>Filter addresses by attempts</h2>
          <p>
            Grey out addresses after this many unanswered knocks. Use this when
            revisiting maps to target addresses where no one answered.
          </p>
          <p>
            For example: If you select '2' below, an address will be greyed out
            after it has been knocked with no answer at least 2 times in this
            campaign. If it has been knocked once with no answer, it will be
            purple.
          </p>
          <p className="font-medium">
            NOTE: An address will always be greyed out after someone has
            answered the door.
          </p>
        </div>
        <Slider
          id="unanswered_threshold"
          value={unansweredThreshold}
          onChange={(e) => setUnansweredThreshold(parseInt(e.target.value))}
          options={[
            { label: "Grey out after 1 attempt (DEFAULT)", value: 1 },
            { label: "Grey out after 2 attempts", value: 2 },
            { label: "Grey out after 3 attempts", value: 3 },
            { label: "Grey out after 4 attempts", value: 4 },
            { label: "Grey out after 5 attempts", value: 5 },
          ]}
        />
        <div className="flex gap-4 justify-end">
          <button
            className="btn btn-warning"
            onClick={(e) => {
              e.preventDefault();
              setUnansweredThreshold(1);
            }}
          >
            Reset
          </button>
          <button className="btn btn-neutral">Close</button>
        </div>
      </div>
    </ModalDialog>
  );
};

export default UnansweredFilterModal;
