import { Combobox, Transition } from "@headlessui/react";
import { useState } from "react";
import take from "lodash/take";
import ReactScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";

// TODO tidy these interfaces
interface IOption {
  label: string;
  value: string | number;
}

interface Props {
  label?: string;
  value?: string | number;
  onChange: (value: string | number) => void;
  options: IOption[];
  disabled?: boolean;
}

const ComboBox = ({
  label,
  value,
  onChange,
  options,
  disabled = false,
}: Props) => {
  const [query, setQuery] = useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  const limitedOptions = take(filteredOptions, 100);

  return (
    <>
      {label && (
        <label className="label text-base-content">
          <span className="label-text">{label}</span>
        </label>
      )}
      <Combobox value={value} onChange={onChange} disabled={disabled}>
        {({ open }) => (
          <div className="relative">
            <div className="w-full relative">
              <Combobox.Input
                onChange={(event) => setQuery(event.target.value)}
                className="input input-bordered pr-0 w-full"
              />
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <ReactScrollIntoViewIfNeeded
                  className="absolute h-72 w-full"
                  options={{
                    scrollMode: "always",
                  }}
                >
                  <Combobox.Options className="mt-1 max-h-48 w-full overflow-auto rounded-md bg-base-100 py-1 text-base shadow-lg ring-black ring-opacity-5 focus:outline-none sm:text-sm z-30">
                    {limitedOptions.map(({ label, value }) => (
                      <Combobox.Option
                        key={value}
                        value={value}
                        className="p-2 cursor-pointer"
                      >
                        {label}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </ReactScrollIntoViewIfNeeded>
              </Transition>
            </div>
          </div>
        )}
      </Combobox>
    </>
  );
};

export default ComboBox;
