import { IAddress } from "../types";

export const formatBuildingAddress = (address: IAddress) => {
  return `${address.street_number}${address.part_number} ${
    address.street_name
  }${address.street_type ? ` ${address.street_type}` : ``}, ${
    address.place_name
  }`;
};

export const formatAddress = (address: IAddress): string => {
  const {
    flat_number,
    street_number,
    part_number,
    street_name,
    street_type,
    place_name,
  } = address;
  const formattedAddress = `${
    flat_number ? flat_number + "/" : ""
  }${street_number}${part_number} ${street_name}${
    street_type ? ` ${street_type}` : ``
  }, ${place_name}`;
  return formattedAddress;
};
