import useStore from "../../utils/store";
import Tutorial from "../Tutorial";
import { useLocation } from "react-router-dom";
import {
  PencilSquareIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import ModalDialog from "./ModalDialog";

const TopBar = () => {
  const { pathname } = useLocation();

  const selectedMap = useStore((state) => state.selectedMap);
  const setSelectedMap = useStore((state) => state.setSelectedMap);
  const setSelectedMapId = useStore((state) => state.setSelectedMapId);

  const TITLES: any = {
    "": !selectedMap ? "Map selection" : `Map ${selectedMap.name}`,
    stats: "Stats",
    settings: "Settings",
    organising: "Organising",
  };

  const title = TITLES[pathname.split("/")[1]];

  return (
    <div className="navbar bg-primary flex justify-between px-3 text-primary-content">
      <div className="text-xl font-bold">{title}</div>

      {pathname === "/" && (
        <div className="flex gap-4">
          {selectedMap && (
            <button
              onClick={() => {
                setSelectedMapId(undefined);
                setSelectedMap(undefined);
              }}
            >
              <PencilSquareIcon className="h-6 w-6" />
            </button>
          )}
          <button onClick={() => (window as any).help_modal.showModal()}>
            <QuestionMarkCircleIcon className="h-6 w-6 " />
          </button>
        </div>
      )}
      <ModalDialog id="help_modal">
        <Tutorial />
      </ModalDialog>
    </div>
  );
};
export default TopBar;
