import clsx from "clsx";

interface Props {
  id: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
}

const TextInput = ({
  id,
  value,
  onChange,
  placeholder,
  disabled,
  onKeyPress,
  className,
}: Props) => (
  <input
    id={id}
    type="text"
    value={value}
    onChange={(event) => onChange(event.target.value)}
    placeholder={placeholder}
    className={clsx("input input-bordered", className)}
    disabled={disabled}
    onKeyPress={onKeyPress}
  />
);

export default TextInput;
