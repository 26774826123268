import { IMap, ISubmissionMode } from "../../types";
import api from "./base";

export const getAllMaps = async () => await api.getData("/maps");

export const getAllMapPolygons = async (
  municipalityId: number,
  campaignId: number,
) => {
  const response = await api.getData(
    `/map_polygons/${municipalityId}/${campaignId}`,
  );
  return response.map((mapItem: any) => ({
    ...mapItem,
    geometry: JSON.parse(mapItem.geometry),
  }));
};

export const getMapById = async (id: number, campaignId: number) => {
  const response = await api.getData(`/maps/${id}/${campaignId}`);

  try {
    const map: IMap = {
      ...response,
      geometry: JSON.parse(response.geometry),
    };

    return map;
  } catch (e) {
    console.log("Failed to load map");
    console.error(e);
    throw e;
  }
};

export const updateLetterboxedStatus = async (
  letterboxed: boolean,
  id: number,
  campaignId: number,
) => await api.postData(`/maps`, { id, letterboxed, campaignId });

export const updateCompletionStatus = async (
  mapId: number,
  campaignId: number,
  campaignType: ISubmissionMode,
  completed: boolean,
) =>
  await api.postData(`/maps`, { mapId, campaignId, campaignType, completed });
