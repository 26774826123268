import { useEffect, useState } from "react";
import { IMap } from "../types";
import { getMapStats } from "../utils/api/stats";
import Loader from "../components/Loader";
import useStore from "../utils/store";
import WarningIcon from "../components/misc/WarningIcon";
import { useSnackbar } from "notistack";

function createData(
  name: string,
  dayCount: number,
  weekCount: number,
  allCount: number,
) {
  return { name, dayCount, weekCount, allCount };
}

const Stats = () => {
  const { enqueueSnackbar } = useSnackbar();
  const map = useStore((state) => state.selectedMap);
  const user = useStore((state) => state.user);
  const campaign = useStore((state) => state.selectedCampaign);

  const [statsData, setStatsData] = useState<any>(null);
  const [rows, setRows] = useState<any[]>([]);
  const [userRows, setUserRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const fetchedStats = await getMapStats(
          (map as IMap).id,
          campaign?.id as number,
        );
        setStatsData(fetchedStats);
      } catch (error) {
        enqueueSnackbar("Something went wrong. Please try again.", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    };
    map !== undefined && fetchStats();
  }, [map]);

  useEffect(() => {
    if (!statsData || !map) return;
    let localRows = [];
    localRows.push(
      createData(
        map.name,
        statsData.map_count_day,
        statsData.map_count_week,
        statsData.map_count_alltime,
      ),
    );
    localRows.push(
      createData(
        map.wardName,
        statsData.district_count_day,
        statsData.district_count_week,
        statsData.district_count_alltime,
      ),
    );
    localRows.push(
      createData(
        map.municipalityName,
        statsData.region_count_day,
        statsData.region_count_week,
        statsData.region_count_alltime,
      ),
    );
    localRows.push(
      createData(
        "Total",
        statsData.total_count_day,
        statsData.total_count_week,
        statsData.total_count_alltime,
      ),
    );
    setRows(localRows);
    let userLocalRows = [];
    userLocalRows.push(
      createData(
        map.name,
        statsData.user_map_count_day,
        statsData.user_map_count_week,
        statsData.user_map_count_alltime,
      ),
    );
    userLocalRows.push(
      createData(
        map.wardName,
        statsData.user_district_count_day,
        statsData.user_district_count_week,
        statsData.user_district_count_alltime,
      ),
    );
    userLocalRows.push(
      createData(
        map.municipalityName,
        statsData.user_region_count_day,
        statsData.user_region_count_week,
        statsData.user_region_count_alltime,
      ),
    );
    userLocalRows.push(
      createData(
        "Total",
        statsData.user_total_count_day,
        statsData.user_total_count_week,
        statsData.user_total_count_alltime,
      ),
    );
    setUserRows(userLocalRows);
  }, [statsData]);

  if (!map) {
    return (
      <div className="alert alert-warning my-4">
        <WarningIcon />
        Please select a map to see stats.
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="mt-4 mb-16 flex flex-col gap-4">
          <div className="card card-compact border-2 border-primary">
            <div className="card-body">
              <h2 className="card-title mb-2">
                Doors knocked in this campaign
              </h2>
              <div className="overflow-x-auto">
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th align="right"> Today </th>
                      <th align="right">Past 7 days</th>
                      <th align="right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row: any) => (
                      <tr key={row.name}>
                        <th>{row.name}</th>
                        <td align="right">{row.dayCount}</td>
                        <td align="right">{row.weekCount}</td>
                        <td align="right">{row.allCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {user && (
            <div className="card card-compact border-2 border-primary">
              <div className="card-body">
                <h2 className="card-title mb-2">
                  Doors knocked by {user.displayName}
                </h2>
                <div className="overflow-x-auto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th align="right">Today</th>
                        <th align="right">Past 7 days</th>
                        <th align="right">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userRows.map((row: any) => (
                        <tr key={"user" + row.name}>
                          <th>{row.name}</th>
                          <td align="right">{row.dayCount}</td>
                          <td align="right">{row.weekCount}</td>
                          <td align="right">{row.allCount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Stats;
