import { XMarkIcon } from "@heroicons/react/24/solid";

interface Props {
  id: string;
  children: React.ReactNode;
}

const ModalDialog = ({ id, children }: Props) => (
  <dialog id={id} className="modal">
    <form method="dialog" className="modal-box">
      <button className="btn btn-sm btn-circle btn-base-content absolute right-2 top-2">
        <XMarkIcon className="h-6 w-6" />
      </button>

      {children}
    </form>
    <form method="dialog" className="modal-backdrop">
      <button>close</button>
    </form>
  </dialog>
);

export default ModalDialog;
