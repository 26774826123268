import { useState } from "react";
import MapBrowser from "../components/MapBrowser";
import CampaignSelection from "../components/CampaignSelection";
import useStore from "../utils/store";
import Select from "../components/form/Select";
import Label from "../components/form/Label";

// TODO Don't hardcode these
const municipalities = [
  { id: 1, name: "Ballarat" },
  { id: 2, name: "Ballarat" },
  { id: 3, name: "Banyule" },
  { id: 4, name: "Bayside" },
  { id: 5, name: "Brimbank" },
  { id: 6, name: "Buloke" },
  { id: 7, name: "Casey" },
  { id: 8, name: "Central Goldfields" },
  { id: 9, name: "Corangamite" },
  { id: 10, name: "Darebin" },
  { id: 11, name: "Frankston" },
  { id: 12, name: "Gippsland" },
  { id: 13, name: "Glen Eira" },
  { id: 14, name: "Greater Bendigo" },
  { id: 15, name: "Greater Geelong" },
  { id: 16, name: "Greater Shepparton" },
  { id: 17, name: "Hindmarsh" },
  { id: 18, name: "Hobsons Bay" },
  { id: 19, name: "Horsham" },
  { id: 20, name: "Hume" },
  { id: 21, name: "Inner Melbourne" },
  { id: 22, name: "Kingston" },
  { id: 23, name: "Knox" },
  { id: 24, name: "Latrobe" },
  { id: 25, name: "Macedon Ranges" },
  { id: 26, name: "Manningham" },
  { id: 27, name: "Mansfield" },
  { id: 28, name: "Maribyrnong" },
  { id: 29, name: "Maroondah" },
  { id: 30, name: "Melton" },
  { id: 31, name: "Merri-bek" },
  { id: 32, name: "Mildura" },
  { id: 33, name: "Mitchell" },
  { id: 34, name: "Monash" },
  { id: 35, name: "Moonee Valley" },
  { id: 36, name: "Mornington Peninsula" },
  { id: 37, name: "Mount Alexander" },
  { id: 38, name: "Nillumbik" },
  { id: 39, name: "North Central" },
  { id: 40, name: "North East" },
  { id: 41, name: "Northern Grampians" },
  { id: 42, name: "North West" },
  { id: 43, name: "Port Phillip" },
  { id: 44, name: "South Central" },
  { id: 45, name: "South West" },
  { id: 46, name: "Stonnington" },
  { id: 47, name: "Surf Coast" },
  { id: 48, name: "Swan Hill" },
  { id: 49, name: "Wangaratta" },
  { id: 50, name: "Warrnambool" },
  { id: 51, name: "Whitehorse" },
  { id: 52, name: "Whittlesea" },
  { id: 53, name: "Wodonga" },
  { id: 54, name: "Wyndham" },
  { id: 55, name: "Yarra" },
  { id: 56, name: "Yarra Ranges" },
  { id: 57, name: "Yarriambiack" },
];

const Organising = () => {
  const [viewMaps, setViewMaps] = useState(false);
  // const [viewLetterboxingMaps, setViewLetterboxingMaps] = useState(false);
  const [selectedMunicipalityId, setSelectedMunicipalityId] = useState<
    number | undefined
  >();
  const selectedCampaign = useStore((state) => state.selectedCampaign);
  const setSelectedCampaign = useStore((state) => state.setSelectedCampaign);

  const IS_CALWELL_CAMPAIGN = selectedCampaign?.id === 56;

  return (
    <>
      {viewMaps && selectedCampaign && (
        <MapBrowser
          municipalityId={selectedMunicipalityId as number}
          campaignId={selectedCampaign.id}
          letterboxing={selectedCampaign?.type === "Letterboxing"}
        />
      )}
      {!viewMaps && (
        <div className="my-4 flex flex-col">
          <Label>Select a campaign</Label>
          <CampaignSelection
            campaign={selectedCampaign}
            setCampaign={setSelectedCampaign}
          />
          {!IS_CALWELL_CAMPAIGN && (
            <Select
              id="demo-simple-select"
              value={selectedMunicipalityId}
              label="Select a council"
              onChange={(value) => setSelectedMunicipalityId(value as number)}
              options={municipalities.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
          )}
          <div className="flex flex-col md:flex-row gap-4 mt-4">
            {/*
            <button
              className="btn btn-primary"
              onClick={() => setViewMaps(true)}
            >
              View district maps (doorknocking)
            </button>
                */}

            <button
              className="btn btn-primary"
              onClick={() => setViewMaps(true)}
            >
              View {IS_CALWELL_CAMPAIGN ? "Calwell" : "council"} maps
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Organising;
