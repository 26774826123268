import { useEffect, useState } from "react";
import { getAssignedMapIds } from "../utils/api/assignments";
import useStore from "../utils/store";
import Label from "./form/Label";
import clsx from "clsx";

interface IPartialMap {
  id: number;
  name: string;
  letterboxedTimestamp: string;
  doorknockingCompleted: boolean;
}

const AssignedMaps = () => {
  const [assignedMaps, setAssignedMaps] = useState<IPartialMap[] | null>(null);
  const selectedCampaign = useStore((state) => state.selectedCampaign);
  const setSelectedMapId = useStore((state) => state.setSelectedMapId);

  useEffect(() => {
    const fetchAssignedMapIds = async () => {
      if (!selectedCampaign) return;
      const fetchedAssignedMapIds = await getAssignedMapIds(
        selectedCampaign.id,
      );

      setAssignedMaps(fetchedAssignedMapIds);
    };
    setAssignedMaps(null);
    fetchAssignedMapIds();
  }, [selectedCampaign]);

  const mapIsCompleted = (map: IPartialMap) => {
    if (!selectedCampaign) return false;
    if (selectedCampaign?.type === "Letterboxing") {
      return map.letterboxedTimestamp !== null;
    } else {
      return map.doorknockingCompleted;
    }
  };

  return (
    <div>
      <Label>Select an assigned map</Label>
      <div className="flex flex-wrap gap-2">
        {assignedMaps === null && (
          <span className="loading loading-spinner loading-md" />
        )}
        {assignedMaps?.length === 0 && (
          <div className="alert alert-warning">
            You have no maps assigned for this campaign
          </div>
        )}
        {assignedMaps?.map((assignedMap) => {
          return (
            <button
              key={assignedMap.id}
              className={clsx("btn", {
                "btn-accent": !mapIsCompleted(assignedMap),
                "btn-ghost btn-outline opacity-50": mapIsCompleted(assignedMap),
              })}
              onClick={() => setSelectedMapId(assignedMap.id)}
            >
              {assignedMap.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};
export default AssignedMaps;
