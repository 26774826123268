import { Typography } from "@mui/material";
import { IMap } from "../types";

const MapAssigner = ({ map }: { map: IMap }) => {
  let status = "Unassigned";
  if (map.assigned && map.assigned?.length > 0) status = "Assigned";
  if (map.letterboxedTimestamp) status = "Completed";
  return (
    <>
      <Typography variant="h6" component="div" color={"#262626"}>
        {map.name}
      </Typography>
      <Typography variant="h6" component="div" color={"#262626"}>
        Status: {status}
        <br />
        {map.assigned && <>Assigned to: {map.assigned.join(", ")}</>}
      </Typography>
    </>
  );
};

export default MapAssigner;
