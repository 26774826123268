interface Props {
  label: string;
  children: React.ReactNode;
  groupName?: string;
}

const Accordion = ({ groupName, label, children }: Props) => (
  <div className="collapse collapse-arrow border-primary border-2">
    <input type="checkbox" />
    <div className="collapse-title text-lg font-medium">{label}</div>
    <div className="collapse-content">
      <div className="p2">{children}</div>
    </div>
  </div>
);

export default Accordion;
