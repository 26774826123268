import { LatLngTuple } from "leaflet";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import AssignedMaps from "../components/AssignedMaps";
import CampaignSelection from "../components/CampaignSelection";
import MapSelectionField from "../components/MapSelection";
import { AddressCollectionI, IMap } from "../types";
import { getAddressesByMapId } from "../utils/api/addresses";
import { getMapById } from "../utils/api/maps";
import useStore from "../utils/store";
import Knocking from "./Knocking";

let watchPositionId: number | undefined;
const MapScreen = () => {
  const { enqueueSnackbar } = useSnackbar();
  const isAuthed = useStore((state) => state.isAuthed);
  const selectedCampaign = useStore((state) => state.selectedCampaign);
  const setSelectedCampaign = useStore((state) => state.setSelectedCampaign);
  const selectedMapId = useStore((state) => state.selectedMapId);
  const setSelectedMapId = useStore((state) => state.setSelectedMapId);
  const selectedMap = useStore((state) => state.selectedMap);
  const setSelectedMap = useStore((state) => state.setSelectedMap);
  const geolocation = useStore((state) => state.geolocation);

  const practiceMode = useStore((state) => state.practiceMode);

  const [userLocation, setUserLocation] = useState<{
    position: LatLngTuple;
    heading: number | null;
  }>({ position: [0, 0], heading: null });

  const [addresses, setAddresses] = useState<AddressCollectionI>([]);

  const setUserGeolocation = (position: GeolocationPosition) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const heading = position.coords.heading;
    setUserLocation({ position: [latitude, longitude], heading });
  };

  const cleanupWatchSubscriber = () => {
    // Unregister handler, if we have one
    if (watchPositionId) {
      navigator.geolocation.clearWatch(watchPositionId);
      watchPositionId = undefined;
    }
  };

  useEffect(() => {
    if (!geolocation) {
      cleanupWatchSubscriber();
    } else {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(setUserGeolocation);
        const id = navigator.geolocation.watchPosition(setUserGeolocation);
        watchPositionId = id;
      } else {
        // TODO inform user that geolocation is not available
      }
    }

    return cleanupWatchSubscriber;
  }, [geolocation]);

  const fetchAddresses = async () => {
    if (!isAuthed) return;
    if (selectedMapId === undefined) return;
    const fetchedAddresses = await getAddressesByMapId(
      selectedMapId,
      selectedCampaign?.id,
      practiceMode,
    );
    setAddresses(fetchedAddresses);
  };

  // Load addresses
  useEffect(() => {
    fetchAddresses();
  }, [selectedMapId, practiceMode, isAuthed]);

  const reloadAddresses = async () => {
    await fetchAddresses();
  };

  // Reload addresses data every 60 seconds
  const ref = useRef(null);
  useEffect(() => {
    (ref.current as any) = setInterval(reloadAddresses, 60000);
    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, []);

  // Load detailed data for selected map
  const fetchMap = async (id: number) => {
    if (!isAuthed) return;
    try {
      const fetchedMap = await getMapById(id, selectedCampaign?.id as number);
      setSelectedMap(fetchedMap);
    } catch (e) {
      enqueueSnackbar("Failed to load map.", { variant: "error" });
      setSelectedMap(undefined);
      setSelectedMapId(undefined);
    }
  };

  useEffect(() => {
    selectedMapId !== undefined && fetchMap(selectedMapId);
  }, [selectedMapId, isAuthed]);

  if (!isAuthed) return null;

  return (
    <>
      {(selectedMapId === undefined || selectedCampaign === undefined) && (
        <div className="my-4 flex flex-col gap-4">
          <div className="card card-compact border-2 border-primary">
            <div className="card-body">
              <h2 className="card-title mb-2">Select a campaign</h2>
              <CampaignSelection
                campaign={selectedCampaign}
                setCampaign={setSelectedCampaign}
              />
            </div>
          </div>
          {selectedCampaign && (
            <div className="card card-compact border-2 border-primary">
              <div className="card-body">
                <h2 className="card-title mb-2">Select a map</h2>
                <AssignedMaps />
                <div className="divider">OR</div>
                <MapSelectionField
                  mapId={selectedMapId}
                  setMapId={setSelectedMapId}
                  disabled={!selectedCampaign}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {selectedMap && selectedCampaign && addresses && (
        <Knocking
          map={selectedMap as IMap}
          campaign={selectedCampaign}
          addresses={addresses}
          reloadAddresses={reloadAddresses}
          userLocation={userLocation}
          reloadMap={() => fetchMap(selectedMapId as number)}
        />
      )}
    </>
  );
};

export default MapScreen;
