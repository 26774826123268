interface Props {
  id: string;
  value: boolean;
  onChange: any;
}

const CheckBox = ({ id, value, onChange }: Props) => (
  <div className="form-control">
    <input
      id={id}
      type="checkbox"
      checked={value}
      className="checkbox checkbox-primary"
      onChange={onChange}
    />
  </div>
);

export default CheckBox;
