import HashLoader from "react-spinners/HashLoader";
import { CSSProperties } from "react";


const override: CSSProperties = {
  display: "block",
  margin: "100px auto",
  borderColor: "red",
};

const Loader = () => {
  return (
    <div
    style={{
      height: "100vh",
      width: "100vw",
      backgroundColor: "rgba(255, 255, 255, 0.51)",
      position: "fixed",
      top: 0,
      zIndex: 1001,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"

    }}
    >
      <HashLoader size={100} loading cssOverride={override}/>

    </div>
  )

}

export default Loader;