import clsx from "clsx";

interface Props {
  alt?: boolean;
  children?: React.ReactNode;
}

const Label = ({ alt, children }: Props) =>
  children ? (
    <label className="label text-base-content">
      <span
        className={clsx({
          "label-text": !alt,
          "label-text-alt": alt,
        })}
      >
        {children}
      </span>
    </label>
  ) : null;

export default Label;
