import { useLocation, useNavigate } from "react-router-dom";
import {
  ChartBarIcon,
  Cog6ToothIcon,
  UserGroupIcon,
  HomeIcon,
} from "@heroicons/react/20/solid";
import useStore from "../../utils/store";
import { clsx } from "clsx";

interface IBottomNavButton {
  label: string;
  icon: JSX.Element;
  path: string;
}

const BottomBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const user = useStore((state) => state.user);
  const submissionMode = useStore((state) => state.submissionMode);

  const iconClasses = "h-4 w-4";

  const buttons: IBottomNavButton[] = [
    {
      label:
        submissionMode === "Doorknocking" ? "Doorknocking" : "Letterboxing",
      icon: <HomeIcon className={iconClasses} />,
      path: "/",
    },
    {
      label: "Stats",
      icon: <ChartBarIcon className={iconClasses} />,

      path: "/stats",
    },
    {
      label: "Settings",
      icon: <Cog6ToothIcon className={iconClasses} />,
      path: "/settings",
    },
    ...(user?.isCoordinator
      ? [
          {
            label: "Organising",
            icon: <UserGroupIcon className={iconClasses} />,
            path: "/organising",
          },
        ]
      : []),
  ];

  return (
    <div className="btm-nav btm-nav-sm bg-primary z-50 text-primary-content">
      {buttons.map((button) => (
        <button
          key={button.path}
          className={clsx({
            "active bg-primary-focus": pathname === button.path,
          })}
          onClick={() => navigate(button.path)}
        >
          {button.icon}
          <span className="btm-nav-label">{button.label}</span>
        </button>
      ))}
    </div>
  );
};

export default BottomBar;
