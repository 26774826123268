import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Auth from "./screens/Auth";
import Login from "./screens/Login";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// root.render(<RouterProvider router={router} />);
root.render(<App />);
