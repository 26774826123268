import { useEffect, useState } from "react";
import { ICampaign } from "../types";
import { getAllCampaigns } from "../utils/api/campaigns";
import Select from "./form/Select";

interface Props {
  campaign?: ICampaign;
  setCampaign: (campaign?: ICampaign) => void;
}
const CampaignSelection = ({ campaign, setCampaign }: Props) => {
  const [campaigns, setCampaigns] = useState<ICampaign[] | null>();

  // Load campaigns
  useEffect(() => {
    const fetchCampaigns = async () => {
      const fetchedCampaigns = await getAllCampaigns();
      setCampaigns(fetchedCampaigns);
    };
    fetchCampaigns();
  }, []);

  const setCampaignFromId = (campaignId: number) => {
    if (!campaigns) return;
    const campaign = campaigns.find((campaign) => campaign.id === campaignId);
    if (campaign) {
      setCampaign(campaign);
    }
  };

  if (!campaigns)
    return <span className="loading loading-spinner loading-md" />;

  if (campaigns.length === 1) {
    // Avoids bug when only campaign is available and unselectable
    setCampaignFromId(campaigns[0].id);
  }

  const options = campaigns.map(({ id, name }) => ({ label: name, value: id }));

  return (
    <Select
      id="campaign-selection"
      placeholder="Select a campaign"
      value={campaign?.id}
      onChange={(value) => setCampaignFromId(parseInt(value as string))}
      options={options}
    />
  );
};

export default CampaignSelection;
