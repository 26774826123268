import axios, { Axios } from "axios";
import { getToken } from "../store";

class API {
  token: string | null;
  axios: Axios;

  constructor() {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_API_BASE,
    });
    this.axios.defaults.headers.common["Content-Type"] = "application/json";
    this.axios.interceptors.request.use(
      (req) => {
        if (this.token) {
          req.headers["Authorization"] = `Bearer ${this.token}`;
        }
        return req;
      },
      (e) => Promise.reject(e),
    );
    this.token = null;
  }

  updateToken = (token: string) => {
    this.token = token;
  };

  clearToken = () => {
    this.token = null;
  };

  getData = async (url: string, params?: Object) => {
    const response = await this.axios.request({
      url,
      params,
    });
    return response.data;
  };

  postData = async (url: string, data: Object) => {
    const response = await this.axios.request({
      url,
      method: "POST",
      data,
    });
    return response.data;
  };
}

const api = new API();

export default api;
