import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import SplashScreen from "../components/SplashScreen";
import { verifyToken } from "../utils/store";

const Verify = () => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("Verifying your information");
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  const handleSuccess = () => {
    setMessage("Verified! Redirecting to home page");
    setLoading(false);
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const handleError = () => {
    setMessage("Something went wrong. Please try again.");
    setLoading(false);
    setTimeout(() => {
      navigate("/login");
    }, 2000);
  };

  useEffect(() => {
    (async () => {
      if (token) verifyToken(token, handleSuccess, handleError);
    })();
  }, [token]);

  return <SplashScreen loading={loading} message={message} />;
};

export default Verify;
