import { useEffect, useState } from "react";
import { getAllMaps } from "../utils/api/maps";
import { IMap } from "../types";
import ComboBox from "./form/ComoboBox";

interface Props {
  mapId: number | undefined;
  setMapId: (mapId: number) => void;
  disabled?: boolean;
}

const MapSelection = ({ mapId, setMapId, disabled = false }: Props) => {
  const [maps, setMaps] = useState<IMap[] | null>(null);

  // Load maps
  useEffect(() => {
    const fetchMaps = async () => {
      const fetchedMaps = await getAllMaps();
      setMaps(fetchedMaps);
    };
    fetchMaps();
  }, []);

  if (!maps) {
    return <></>;
  }

  return (
    <ComboBox
      label="Select another map by name"
      value={mapId}
      onChange={(value) => setMapId(value as number)}
      options={maps.map(({ id, name }) => ({ label: name, value: id }))}
      disabled={disabled}
    />
  );
};

export default MapSelection;
